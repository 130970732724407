import React, { Component } from "react";
import Lottie from "lottie-web";

class LottieAnimate extends Component {
  componentDidMount() {
    const { options, name } = this.props;

    if (document.getElementById(name).children.length === 0) {
      Lottie.loadAnimation({
        container: document.getElementById(name),
        renderer: "svg",
        loop: options.loop || false,
        autoplay: options.autoplay || true,
        path: options.path || null,
        animationData: options.animationData || null,
      });
    }
  }

  render() {
    const { name, width, height } = this.props;

    return (
      <div
        id={name}
        style={{
          height: height || "200px",
          width: width || "200px",
        }}
      />
    );
  }
}

export default LottieAnimate;
